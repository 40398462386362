import plate from "./static/plate.png";
import plate2 from "./static/plate2.png";
import plate3 from "./static/plate4.png";
import plate4 from "./static/plate3.png";
import burger1 from "./static/burger1.png";
import burger2 from "./static/burger2.png";
import burger3 from "./static/burger3.png";
import pizza1 from "./static/pizza1.png";
import pizza2 from "./static/pizza2.png";
import pizza3 from "./static/pizza3.png";
import pizza4 from "./static/pizza4.png";
import pizza5 from "./static/pizza5.png";
import pizzaoffer from "./static/pizzaoffer.jpg";
import plateoffer from "./static/plateoffer.jpg";
import burgeroffer from "./static/burgeroffer.jpg";
import addict from "./static/addict.png"
export const restaurantmenus = [
  {
    name: "addict",
    logo: addict,
    theme:{
      background:"white",
      main:"#F9DD63",
      text:"black"
    },
    menu: [
      {
        category: "Offers",
        categoryimage: pizza3,
        items: [
          {
            nametop: "Pepperoni",
            namebottom: "Pizza",
            description:
              "Grab our mouthwatering Boneless Chicken Plate for just $9 today! Limited time offer!",
            price: "9",
            image: pizzaoffer,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description:
              "Grab our mouthwatering Boneless Chicken Plate for just $9 today! Limited time offer!",
            price: "10",
            image: plateoffer,
          },
          {
            nametop: "Beef",
            namebottom: "Burger",
            description:
              "Grab our mouthwatering Boneless Chicken Plate for just $9 today! Limited time offer!",
            price: "15",
            image: burgeroffer,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description:
              "Grab our mouthwatering Boneless Chicken Plate for just $9 today! Limited time offer!",
            price: "14",
            image: plateoffer,
          },
        ],
      },

      {
        category: "Pizza",
        categoryimage: pizza1,
        items: [
          {
            nametop: "California",
            namebottom: "Pizza",
            description: "",
            price: "9",
            image: pizza1,
          },
          {
            nametop: "Greek",
            namebottom: "Pizza",
            description: "",
            price: "9",
            image: pizza2,
          },
          {
            nametop: "Chicago",
            namebottom: "Pizza",
            description: "",
            price: "9",
            image: pizza4,
          },
          {
            nametop: "BBQ Chicken",
            namebottom: "Pizza",
            description: "",
            price: "9",
            image: pizza5,
          },
          {
            nametop: "California",
            namebottom: "Pizza",
            description: "",
            price: "9",
            image: pizza1,
          },
          {
            nametop: "Greek",
            namebottom: "Pizza",
            description: "",
            price: "9",
            image: pizza2,
          },
          {
            nametop: "Pepperoni",
            namebottom: "Pizza",
            description: "",
            price: "9",
            image: pizza4,
          },
          {
            nametop: "BBQ Chicken",
            namebottom: "Pizza",
            description: "",
            price: "9",
            image: pizza5,
          },
          {
            nametop: "California",
            namebottom: "Pizza",
            description: "",
            price: "9",
            image: pizza1,
          },
          {
            nametop: "Greek",
            namebottom: "Pizza",
            description: "",
            price: "9",
            image: pizza2,
          },
          {
            nametop: "Pepperoni",
            namebottom: "Pizza",
            description: "",
            price: "9",
            image: pizza4,
          },
          {
            nametop: "BBQ Chicken",
            namebottom: "Pizza",
            description: "",
            price: "9",
            image: pizza5,
          },
        ],
      },
      {
        category: "Plates",
        categoryimage: plate3,
        items: [
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicsdas",
            description: "",
            price: "9",
            image: plate2,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate3,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate4,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate2,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate3,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate4,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate2,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate2,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate3,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate4,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate2,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate3,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate4,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate2,
          },
        ],
      },
      {
        category: "Burgers",
        categoryimage: burger2,
        items: [
          {
            nametop: "Beaf",
            namebottom: "Burger",
            description: "",
            price: "9",
            image: burger1,
          },
          {
            nametop: "Double Beaf",
            namebottom: "Burger",
            description: "",
            price: "7",
            image: burger2,
          },
          {
            nametop: "Chicken",
            namebottom: "Burger",
            description: "",
            price: "11",
            image: burger3,
          },
          {
            nametop: "Beaf",
            namebottom: "Burger",
            description: "",
            price: "9",
            image: burger1,
          },
          {
            nametop: "Double Beaf",
            namebottom: "Burger",
            description: "",
            price: "7",
            image: burger2,
          },
          {
            nametop: "Chicken",
            namebottom: "Burger",
            description: "",
            price: "11",
            image: burger3,
          },
          {
            nametop: "Beaf",
            namebottom: "Burger",
            description: "",
            price: "9",
            image: burger1,
          },
          {
            nametop: "Double Beaf",
            namebottom: "Burger",
            description: "",
            price: "7",
            image: burger2,
          },
          {
            nametop: "Chicken",
            namebottom: "Burger",
            description: "",
            price: "11",
            image: burger3,
          },
          {
            nametop: "Beaf",
            namebottom: "Burger",
            description: "",
            price: "9",
            image: burger1,
          },
          {
            nametop: "Double Beaf",
            namebottom: "Burger",
            description: "",
            price: "7",
            image: burger2,
          },
          {
            nametop: "Chicken",
            namebottom: "Burger",
            description: "",
            price: "11",
            image: burger3,
          },
        ],
      },
      {
        category: "Drinks",
        categoryimage: plate4,
        items: [
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicsdas",
            description: "",
            price: "9",
            image: plate2,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate3,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate4,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate2,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate3,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate4,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate2,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate2,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate3,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate4,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate2,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate3,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate4,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate2,
          },
        ],
      },
    ],
  },
  {
    name: "almarsa",
    logo: addict,
    theme:{
      background:"white",
      main:"#cce4a9",
      text:"black"
    },
    menu: [
      {
        category: "Offers",
        categoryimage: pizza3,
        items: [
          {
            nametop: "Pepperoni",
            namebottom: "Pizza",
            description:
              "Grab our mouthwatering Boneless Chicken Plate for just $9 today! Limited time offer!",
            price: "9",
            image: pizzaoffer,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description:
              "Grab our mouthwatering Boneless Chicken Plate for just $9 today! Limited time offer!",
            price: "10",
            image: plateoffer,
          },
          {
            nametop: "Beef",
            namebottom: "Burger",
            description:
              "Grab our mouthwatering Boneless Chicken Plate for just $9 today! Limited time offer!",
            price: "15",
            image: burgeroffer,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description:
              "Grab our mouthwatering Boneless Chicken Plate for just $9 today! Limited time offer!",
            price: "14",
            image: plateoffer,
          },
        ],
      },

      {
        category: "Pizza",
        categoryimage: pizza1,
        items: [
          {
            nametop: "California",
            namebottom: "Pizza",
            description: "",
            price: "9",
            image: pizza1,
          },
          {
            nametop: "Greek",
            namebottom: "Pizza",
            description: "",
            price: "9",
            image: pizza2,
          },
          {
            nametop: "Chicago",
            namebottom: "Pizza",
            description: "",
            price: "9",
            image: pizza4,
          },
          {
            nametop: "BBQ Chicken",
            namebottom: "Pizza",
            description: "",
            price: "9",
            image: pizza5,
          },
          {
            nametop: "California",
            namebottom: "Pizza",
            description: "",
            price: "9",
            image: pizza1,
          },
          {
            nametop: "Greek",
            namebottom: "Pizza",
            description: "",
            price: "9",
            image: pizza2,
          },
          {
            nametop: "Pepperoni",
            namebottom: "Pizza",
            description: "",
            price: "9",
            image: pizza4,
          },
          {
            nametop: "BBQ Chicken",
            namebottom: "Pizza",
            description: "",
            price: "9",
            image: pizza5,
          },
          {
            nametop: "California",
            namebottom: "Pizza",
            description: "",
            price: "9",
            image: pizza1,
          },
          {
            nametop: "Greek",
            namebottom: "Pizza",
            description: "",
            price: "9",
            image: pizza2,
          },
          {
            nametop: "Pepperoni",
            namebottom: "Pizza",
            description: "",
            price: "9",
            image: pizza4,
          },
          {
            nametop: "BBQ Chicken",
            namebottom: "Pizza",
            description: "",
            price: "9",
            image: pizza5,
          },
        ],
      },
      {
        category: "Plates",
        categoryimage: plate3,
        items: [
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicsdas",
            description: "",
            price: "9",
            image: plate2,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate3,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate4,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate2,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate3,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate4,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate2,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate2,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate3,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate4,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate2,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate3,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate4,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate2,
          },
        ],
      },
      {
        category: "Burgers",
        categoryimage: burger2,
        items: [
          {
            nametop: "Beaf",
            namebottom: "Burger",
            description: "",
            price: "9",
            image: burger1,
          },
          {
            nametop: "Double Beaf",
            namebottom: "Burger",
            description: "",
            price: "7",
            image: burger2,
          },
          {
            nametop: "Chicken",
            namebottom: "Burger",
            description: "",
            price: "11",
            image: burger3,
          },
          {
            nametop: "Beaf",
            namebottom: "Burger",
            description: "",
            price: "9",
            image: burger1,
          },
          {
            nametop: "Double Beaf",
            namebottom: "Burger",
            description: "",
            price: "7",
            image: burger2,
          },
          {
            nametop: "Chicken",
            namebottom: "Burger",
            description: "",
            price: "11",
            image: burger3,
          },
          {
            nametop: "Beaf",
            namebottom: "Burger",
            description: "",
            price: "9",
            image: burger1,
          },
          {
            nametop: "Double Beaf",
            namebottom: "Burger",
            description: "",
            price: "7",
            image: burger2,
          },
          {
            nametop: "Chicken",
            namebottom: "Burger",
            description: "",
            price: "11",
            image: burger3,
          },
          {
            nametop: "Beaf",
            namebottom: "Burger",
            description: "",
            price: "9",
            image: burger1,
          },
          {
            nametop: "Double Beaf",
            namebottom: "Burger",
            description: "",
            price: "7",
            image: burger2,
          },
          {
            nametop: "Chicken",
            namebottom: "Burger",
            description: "",
            price: "11",
            image: burger3,
          },
        ],
      },
      {
        category: "Drinks",
        categoryimage: plate4,
        items: [
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicsdas",
            description: "",
            price: "9",
            image: plate2,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate3,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate4,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate2,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate3,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate4,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate2,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate2,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate3,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate4,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate2,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate3,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate4,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate2,
          },
        ],
      },
    ],
  },
  {
    name: "mahdi",
    logo: addict,
    theme:{
      background:"white",
      main:"red",
      text:"black"
    },
    menu: [
      {
        category: "Offers",
        categoryimage: pizza3,
        items: [
          {
            nametop: "Pepperoni",
            namebottom: "Pizza",
            description:
              "Grab our mouthwatering Boneless Chicken Plate for just $9 today! Limited time offer!",
            price: "9",
            image: pizzaoffer,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description:
              "Grab our mouthwatering Boneless Chicken Plate for just $9 today! Limited time offer!",
            price: "10",
            image: plateoffer,
          },
          {
            nametop: "Beef",
            namebottom: "Burger",
            description:
              "Grab our mouthwatering Boneless Chicken Plate for just $9 today! Limited time offer!",
            price: "15",
            image: burgeroffer,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description:
              "Grab our mouthwatering Boneless Chicken Plate for just $9 today! Limited time offer!",
            price: "14",
            image: plateoffer,
          },
        ],
      },

      {
        category: "Pizza",
        categoryimage: pizza1,
        items: [
          {
            nametop: "California",
            namebottom: "Pizza",
            description: "",
            price: "9",
            image: pizza1,
          },
          {
            nametop: "Greek",
            namebottom: "Pizza",
            description: "",
            price: "9",
            image: pizza2,
          },
          {
            nametop: "Chicago",
            namebottom: "Pizza",
            description: "",
            price: "9",
            image: pizza4,
          },
          {
            nametop: "BBQ Chicken",
            namebottom: "Pizza",
            description: "",
            price: "9",
            image: pizza5,
          },
          {
            nametop: "California",
            namebottom: "Pizza",
            description: "",
            price: "9",
            image: pizza1,
          },
          {
            nametop: "Greek",
            namebottom: "Pizza",
            description: "",
            price: "9",
            image: pizza2,
          },
          {
            nametop: "Pepperoni",
            namebottom: "Pizza",
            description: "",
            price: "9",
            image: pizza4,
          },
          {
            nametop: "BBQ Chicken",
            namebottom: "Pizza",
            description: "",
            price: "9",
            image: pizza5,
          },
          {
            nametop: "California",
            namebottom: "Pizza",
            description: "",
            price: "9",
            image: pizza1,
          },
          {
            nametop: "Greek",
            namebottom: "Pizza",
            description: "",
            price: "9",
            image: pizza2,
          },
          {
            nametop: "Pepperoni",
            namebottom: "Pizza",
            description: "",
            price: "9",
            image: pizza4,
          },
          {
            nametop: "BBQ Chicken",
            namebottom: "Pizza",
            description: "",
            price: "9",
            image: pizza5,
          },
        ],
      },
      {
        category: "Plates",
        categoryimage: plate3,
        items: [
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicsdas",
            description: "",
            price: "9",
            image: plate2,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate3,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate4,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate2,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate3,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate4,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate2,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate2,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate3,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate4,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate2,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate3,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate4,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate2,
          },
        ],
      },
      {
        category: "Burgers",
        categoryimage: burger2,
        items: [
          {
            nametop: "Beaf",
            namebottom: "Burger",
            description: "",
            price: "9",
            image: burger1,
          },
          {
            nametop: "Double Beaf",
            namebottom: "Burger",
            description: "",
            price: "7",
            image: burger2,
          },
          {
            nametop: "Chicken",
            namebottom: "Burger",
            description: "",
            price: "11",
            image: burger3,
          },
          {
            nametop: "Beaf",
            namebottom: "Burger",
            description: "",
            price: "9",
            image: burger1,
          },
          {
            nametop: "Double Beaf",
            namebottom: "Burger",
            description: "",
            price: "7",
            image: burger2,
          },
          {
            nametop: "Chicken",
            namebottom: "Burger",
            description: "",
            price: "11",
            image: burger3,
          },
          {
            nametop: "Beaf",
            namebottom: "Burger",
            description: "",
            price: "9",
            image: burger1,
          },
          {
            nametop: "Double Beaf",
            namebottom: "Burger",
            description: "",
            price: "7",
            image: burger2,
          },
          {
            nametop: "Chicken",
            namebottom: "Burger",
            description: "",
            price: "11",
            image: burger3,
          },
          {
            nametop: "Beaf",
            namebottom: "Burger",
            description: "",
            price: "9",
            image: burger1,
          },
          {
            nametop: "Double Beaf",
            namebottom: "Burger",
            description: "",
            price: "7",
            image: burger2,
          },
          {
            nametop: "Chicken",
            namebottom: "Burger",
            description: "",
            price: "11",
            image: burger3,
          },
        ],
      },
      {
        category: "Drinks",
        categoryimage: plate4,
        items: [
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicsdas",
            description: "",
            price: "9",
            image: plate2,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate3,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate4,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate2,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate3,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate4,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate2,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate2,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate3,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate4,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate2,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate3,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate4,
          },
          {
            nametop: "Boneless",
            namebottom: "Chicken",
            description: "",
            price: "9",
            image: plate2,
          },
        ],
      },
    ],
  },
];
