import styled from "styled-components";

export const Container = styled.div`
/* width: 100%; */
flex-direction: row;
height: 80vh;
position: relative;
overflow: hidden;
display: flex;
/* display: ${props=>props.activeCategory==0 ?"flex":"flex"}; */

`;
